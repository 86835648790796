import { Link } from "gatsby"
import React from "react"
import { Helpers } from "../../services/Helpers"
import { NatureSpot } from "../../models/NationalPark"
import Colors from "../../constants/Colors"
import Routes from "../../constants/Routes"

interface Props {
  natureSpot: NatureSpot
  parkname: string
}

export default function NatureSpotCard({ natureSpot, parkname }: Props) {
  const link = `/${Helpers.sanitizeUrl(parkname)}/${
    Routes.NATURE_SPOT_ROUTE
  }${Helpers.sanitizeUrl(natureSpot.nature_spot_title[0].text)}/`

  return (
    <div className="max-w-sm lg:max-w-md rounded overflow-hidden shadow-lg mb-6 mr-0 md:mr-4">
      <Link to={link} title={natureSpot.nature_spot_title[0].text}>
        <div
          style={{
            background: `url(${natureSpot.nature_spot_image.url})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            height: 400,
          }}
        ></div>
      </Link>
      <div className="px-6 py-4 border-b border-lightest h-28">
        <Link to={link}>
          <h4 style={{ marginTop: 5, marginBottom: 0, paddingRight: 50 }}>
            {natureSpot.nature_spot_title[0].text}
          </h4>
          <div
            className="inline-block text-xs mr-2 mt-2 py-1 px-2 bg-light rounded-lg hover:bg-light focus:outline-none font-bold"
            style={{ color: Colors.almostBlack }}
          >
            {natureSpot.nature_spot_type}
          </div>
        </Link>
      </div>
    </div>
  )
}
