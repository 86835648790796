import React from "react"
import Layout from "../components/layout/Layout"
import Breadcrumbs from "../components/nationalpark/Breadcrumbs"
import MapSection from "../components/maps/MapSection"
import MetaData from "../components/seo/MetaData"
import NatureSpotSidebar from "../components/sidebar/NatureSpotSidebar"
import { NatureSpot, NationalPark } from "../models/NationalPark"
import NatureSpotComponent from "../components/nationalpark/NatureSpotComponent"

interface Props {
  pageContext: PageContext
  location: any
}

interface PageContext {
  data: NatureSpot
  park: NationalPark
}

const NatureSpotTemplate: React.FunctionComponent<Props> = ({
  pageContext,
  location,
}) => {
  pageContext.park.nature_spots = pageContext.park.nature_spots.filter(
    item =>
      item.nature_spot_title[0].text !==
      pageContext.data.nature_spot_title[0].text
  )

  return (
    <Layout>
      <MetaData
        title={`${pageContext.data.nature_spot_title[0].text} - ${pageContext.park.national_park_name[0].text}`}
        description={`${pageContext.data.nature_spot_title[0].text} in ${pageContext.park.national_park_name[0].text}. Read more about the spot and get directions to visit.`}
        thumbnailUrl={pageContext.data.nature_spot_image.url}
        imageAlt={`${pageContext.data.nature_spot_image.alt}`}
        pageUrl={location.pathname}
      />
      <div className={`container mx-auto px-4`}>
        <Breadcrumbs
          continent={pageContext.park.continent}
          country={pageContext.park.country}
          region={pageContext.park.region[0].text}
          parkName={pageContext.park.national_park_name[0].text}
        />
        <h1 className="mt-2 mb-6">
          {pageContext.data.nature_spot_title[0].text}
        </h1>
      </div>
      <div className={`container mx-auto px-4 grid grid-cols-12 gap-4`}>
        <div className="md:col-span-8 col-span-12">
          <img
            src={pageContext.data.nature_spot_image.url}
            alt={pageContext.data.nature_spot_image.alt}
            loading="eager"
          />
          <p>{pageContext.data.nature_spot_description[0].text}</p>
        </div>
        <NatureSpotSidebar title="Details" natureSpot={pageContext.data} />
      </div>
      <div className="col-span-12">
        <MapSection
          parkname={pageContext.data.nature_spot_title[0].text}
          coordinates={pageContext.data.nature_spot_coordinates}
        />
      </div>
      {pageContext.park.nature_spots.length > 0 && (
        <NatureSpotComponent
          title={"Explore related natural sights"}
          parkname={pageContext.park.national_park_name[0].text}
          subtitle={
            "This park contains more than one natural sight. See the different ones here."
          }
          nature_spots={pageContext.park.nature_spots}
        />
      )}
    </Layout>
  )
}

export default NatureSpotTemplate
